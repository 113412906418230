@tailwind base;
@tailwind components;

@font-face {
  font-family: "Bahij TheSansArabic Light";
  src: local("Bahij TheSansArabic Light"),
    url(./fonts/TheSansArabic-Light.ttf) format("truetype");
}

@tailwind utilities;

@layer utilities {
  @variants responsive {
    .font-arabic {
      font-family: "Bahij TheSansArabic Light";
    }
    .h-80 {
      height: 80vh;
    }

    .min-h-80 {
      min-height: 80vh;
    }

    .h-90 {
      height: 90vh;
    }

    .h-100 {
      height: 100vh;
    }

    .h-125 {
      height: 125vh;
    }

    .h-150 {
      height: 150vh;
    }

    .w-90 {
      width: 90vh;
    }

    .w-100 {
      width: 100vh;
    }

    .w-125 {
      width: 125vh;
    }

    .w-150 {
      width: 150vh;
    }

    .h-new-48 {
      height: 48vh;
    }

    .text-gradient {
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .h-about-pics {
      height: 450px;
    }

    .w-about-pics {
      width: 450px;
    }

    .h-about-pics-xl {
      height: 650px;
    }

    .w-about-pics-xl {
      width: 650px;
    }

    .bg-reghda-gray {
      background-color: #d29e83;
    }

    .bg-reghda-blue {
      background-color: #160c3e;
    }

    .text-reghda-blue {
      color: #160c3e;
    }

    .bg-reghda-pink {
      background-color: #d29e83;
    }

    .text-reghda-pink {
      color: #d29e83;
    }

    .bg-reghda-black {
      background-color: #5b5b5b;
    }

    .text-reghda-black {
      color: #5b5b5b;
    }

    .border-reghda-black {
      border-color: #5b5b5b;
    }

    .bg-grey {
      background-color: #d3d3d3;
    }
    .bg-blue-night {
      background: #160c3e;
    }
    .bg-orange {
      background: #d29e83;
    }
    .bg-purple {
      background: #160c3e;
    }
    .bg-light-orange {
      background: #f5dfe1;
    }

    .text-blue-night {
      color: #160c3e;
    }
    .text-purple {
      color: #160c3e;
    }
    .text-orange {
      color: #d29e83;
    }
    .text-light-orange {
      color: #f5dfe1;
    }

    .border-1 {
      border-width: 1px;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    .text-tiny {
      font-size: 0.25rem;
      line-height: 0.5rem;
    }

    .leading-raghda {
      line-height: 3;
    }

    .tracking-raghda {
      letter-spacing: 0.5em;
    }

    .text-8xl {
      font-size: 4rem;
    }
    .rtl {
      direction: rtl;
    }
  }
}
